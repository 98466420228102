import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
// import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button";
import Cards from "../components/Cards";
import Carousel from "../components/Carousel";
import Layout from "../layouts/Layout";

export default (props) => {
	// const {
	//   id,
	//     price,
	//     title,
	//     url,
	//     description
	// } = props.data.item.nodes

	//  console.log("DEEEL ",download.content)
	let item = props.data.item.nodes[0];
	console.log("props.data.item ", item.id);
	console.log("props.data.item ", item.url);
	return (
		<Layout>
			<div className=' container flex flex-col md:flex-row bg-gray-0 py-12 lg:py-16 w-full'>
				<div className='md:w-2/5 md:pb-8'>
					<Img
						fluid={
							item.childrenEtsyListingImage[1].childFile.childImageSharp.fluid
						}
						alt='Video game poster'
						className='rounded-lg '
					/>
				</div>

				<div className='md:w-3/5 mx-4 lg:pl-8 xl:pl-12'>
					<h1 className='  mt-12 text-2xl text-left leading-tight font-extrabold tracking-tight text-gray-900 sm:text-2xl mb-1'>
						{item.title.split(",")[0]}
					</h1>
					<h2 className='mt-6 text-xl text-left leading-tight  font-semibold tracking-tight text-gray-900 sm:text-2xl'>
						${item.price}
					</h2>
					<hr className='my-3' />
					<div className='flex justify-start my-8'>
					<a href={item.url}><button
							
							className='bg-gray-900 hover:bg-gray-700 text-white font-normal text-sm py-2 px-4 mr-4 uppercase '>
							Buy Digital on Etsy
						</button></a>
						<button
							href={item.url}
							className='bg-transparent hover:bg-gray-900 text-gray-900 font-normal text-sm uppercase hover:text-white py-2 px-4 border border-gray-900 hover:border-transparent '>
							More Info
						</button>
					</div>
					{item.description && (
						<div className='my-4 text-xs text-gray-700 whitespace-pre-line'>
							{item.description}
						</div>
					)}
				</div>
			</div>

			{/*<Newsletter />*/}
		</Layout>
	);
};

export const query = graphql`
	query EtsyItemQUery($id: String!) {
		item: allFeaturedEtsyListing (
      filter: { id: { eq: $id } }
				limit: 12
				sort: { fields: views }
      
      ) {
			totalCount
			nodes {
				id
				description
				price
				title
				url
				childrenEtsyListingImage {
					childFile {
						childImageSharp {
							fluid(maxWidth: 2000, maxHeight: 2000, quality: 85) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	}
`;
